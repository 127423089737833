<script setup>
    /**
     * Dropdown menu to change the locale
     */

    // Import components
    import PrimevueOverlayPanel from 'primevue/overlaypanel';

    // Initialize i18n
    const { locale } = useI18n();

    // Datas initialization
    const panel = ref();

    // Props definition
    const props = defineProps({
        // List of routes by locale
        paths: {
            type: Object,
            required: true,
        },
    });

    /**
     * Return the list of locales from the paths
     * @returns {Array}
     */
    const locales = computed(() => {
        return Object.keys(props.paths).filter(pathLocale => pathLocale !== locale.value);
    });

    /**
     * Toggle the overlay panel
     * @param {Event} event 
     */
    const toggle = (event) => {
        panel.value.toggle(event);
    }

    /**
     * Track the locale change in GTM
     */
    const gtmLanguageChange = (language) => {
        // GTM Change - Language
        dataLayer.push({
            event: 'engagement',
            event_name: 'language',
            language: language,
        });
    }
</script>

<template>
    <div id="flags">
        <div id="flags--selected" :class="{clickable: locales.length}" @click="toggle">
            <NuxtImg
                :src="`/images/flags/${locale}.svg`"
                :alt="`Flag ${locale}`"
            />
        </div>

        <PrimevueOverlayPanel v-if="locales.length" ref="panel" appendTo="#flags">
            <div id="flags--availables">
                <NuxtLink
                    v-for="pathLocale in locales"
                    :key="pathLocale"
                    :to="paths[pathLocale]"
                    class="flag"
                    @click="gtmLanguageChange(pathLocale)"
                >
                    <NuxtImg
                        :src="`/images/flags/${pathLocale}.svg`"
                        :alt="`Flag ${pathLocale}`"
                    />
                </NuxtLink>
            </div>
        </PrimevueOverlayPanel>
    </div>
</template>

<style lang="scss">
    #flags--selected {
        display: flex;
        padding: .325rem 1rem;

        &.clickable {
            cursor: pointer;
        }

        img {
            width: 1.5rem;
        }
    }

    #flags--availables {
        @include flex-column;

        & > a {
            display: inline-flex;

            img {
                width: 1.5rem;
            }
        }
    }

    #flags .p-overlaypanel {
        margin-top: 0;
        top: 3.5rem !important;
        box-shadow: var(--shadow-base);

        &:before,
        &:after {
            content: none;
        }

        .p-overlaypanel-content {
            padding: 1rem !important;
        }
    }
</style>